/* eslint-disable import/prefer-default-export */
import logoSX from './src/assets/icon.png';
import logoSM from './src/assets/logo-sm.png';
import './static/swipe-events.js';

/**
 * Workaround for the weird background color issue, 
 * that shows often up.
 */
function fixHeaderBg() {
    const header = document.querySelector('header');
    const image = header?.querySelector('div[data-placeholder-image]');

    if (image) {
        image.style.background = 'transparent';
    }
}

const fetchConfetti = async () => {
    if (window.sessionStorage) {
        const confettiRefreshTime = 0x2bf20;
        let confetti = sessionStorage.getItem('confetti');

        if (confetti === null) {
            const res = await fetch('/api/confetti');
            
            if (res.ok) {
                const json = await res.json();
                sessionStorage.setItem('confetti', (confetti = json?.confetti ?? false));
                sessionStorage.setItem('confetti_time', Date.now() + confettiRefreshTime);
            }
        }

        window.confetti = confetti
    }
};

const clearConfetti = () => {
    if (window.sessionStorage) {
        sessionStorage.removeItem('confetti');
        sessionStorage.removeItem('confetti_time');
        delete window.confetti;  
    }      
};

class Today {
    constructor() {
        this.date = new Date();
        this.year = this.date.getFullYear();
        this.month = this.date.getMonth() + 1;
        this.day = this.date.getDate();
    }
}

const onClientEntry = async () => {
    if (window.speechSynthesis)
        document.querySelector('body').classList.add('speechSynthesis');

    fixHeaderBg();
    clearConfetti();
    await fetchConfetti();
}

const onPreRouteUpdate = () => {
    if (window.speechSynthesis)
        speechSynthesis.cancel();    
}

const onRouteUpdate = async () => {
    window.today = new Today();   
    const element = document?.querySelector('#navbar'); 

    if (window.IntersectionObserver) {
        new IntersectionObserver(([{ intersectionRatio }]) => {
            if (!element) return;

            if (intersectionRatio === 0) {
                element.style.top = '-272.08px';

                setTimeout(() => {
                    element.style.top = '';
                    element.classList.add('scroll', 'header__bg');
                }, 100);
            } else {
                const hamburger = document.querySelector('.hamburger--container button');
                const overlay = document.querySelector('#overlay');
                
                element.classList.remove('scroll', 'header__bg', 'active');                                
                overlay.classList.remove('active');
                hamburger.classList.remove('opened');
                hamburger.setAttribute('aria-expanded', hamburger.classList.contains('opened'));
            }
        }).observe(document?.querySelector('header') ?? document?.querySelector('body'));
    }

    if (window.ResizeObserver) {
        new ResizeObserver(([{ contentRect, target }]) => {
            const { width } = contentRect;
            const logo = document.querySelector('#logo');

            if (!logo || !target) return;

            if (width > 885) {
                target.classList.remove('mobile');
                logo.src = logoSX;
                logo.style.height = '32px';
                logo.style.width = '32px';
            } else {
                target.classList.add('mobile');
                logo.src = logoSM;
                logo.style.height = '';
                logo.style.width = 'auto';
            }
        }).observe(document?.querySelector('html'));
    }

    if (window.sessionStorage) {        
        const confettiTime = parseInt(sessionStorage.getItem('confetti_time'));
        const confetti = sessionStorage.getItem('confetti');
        
        if (Number.isInteger(confettiTime) && confettiTime < Date.now()) {
            clearConfetti();
            await fetchConfetti();
        } else {
            window.confetti = confetti === 'true';
        }
    }
};

export { onRouteUpdate, onClientEntry, onPreRouteUpdate };
